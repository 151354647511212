import React from "react";
import css from './offerBox.module.scss';
import {Link} from "gatsby";
import ArrowRight from '../../../../../images/icons/arrow_small_right_green.svg';

const OfferBox = props => {
  const {
    image,
    imageAlt,
    name = '',
    link
  } = props;

  return (
    <div className={css.wrapper}>
      <div className={css.imageWrapper}>
        {image && <img src={image} alt={imageAlt} />}
      </div>
      <div className={css.nameWrapper}>
        {name}
      </div>
      {link &&
        <div className={css.buttonWrapper}>
          <Link to={link} className={css.buttonLink}>
              Learn more <span className={css.buttonLinkArrow}><img src={ArrowRight} alt=""/></span>
          </Link>
        </div>
      }
    </div>
  )
};

export default OfferBox;
