import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import css from './careers.module.scss';
import Footer from "../components/shared/Footer/Footer";
import {careersEnabled} from '../../src/components/shared/routes';
import RedirectTo from "../components/shared/RedirectTo/RedirectTo";
import {getLink, getRoutes} from "../components/shared/routes";
import OfferBox from "../components/pages/CareersPage/partials/OfferBox/OfferBox";
import JsLogo from "../images/Careers/deeplai-career-javascript-developer.svg";
import IOSLogo from "../images/Careers/deeplai-career-ios-developer.svg";
import AndroidLogo from "../images/Careers/deeplai-career-android-developer.svg";
import ConstructionTeamLeaderLogo from "../images/Careers/deeplai_career_construction_team_leader.svg";
import PythonLogo from "../images/Careers/deeplai-career-python-developer.svg";
import DMSLogo from "../images/Careers/deeplai-career-digital_marketing_specialist.svg";
import SALogo from "../images/Careers/deeplai_career_sales_admin.svg";
import IBDDOLogo
    from "../images/Careers/deeplai-career-international_business_development_manager_direct_operations.svg";
import IBDIOOLogo
    from "../images/Careers/deeplai-career-international_business_development_manager_indirect_oem_operations.svg";
import DevOpsLogo from "../images/Careers/deeplai-career-devops.svg";
import {graphql, useStaticQuery} from "gatsby";
import PageTitle from "../components/shared/PageTitle/PageTitle";

const CareersPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.Careers.pageTitle;

    const {site} = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                descriptionForCareersPages
              }
            }
          }
        `
    );

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }

    return (
        <Layout>
            <SEO title={pageTitle} description={site.siteMetadata.descriptionForCareersPages}/>

            <PageTitle>{pageTitle}</PageTitle>

            <div className={css.wrapper}>

                <div className={css.title}>Now hiring<br/>Join our team</div>
                <div className={css.offers}>
                    {careersEnabled.list && careersEnabled.android &&
                        <OfferBox
                            image={AndroidLogo}
                            imageAlt={'deeplai career android developer'}
                            name={routes.CareersAndroidDeveloper.pageTitle}
                            link={getLink([routes.CareersAndroidDeveloper])}
                        />
                    }
                    {careersEnabled.list && careersEnabled.iOS &&
                        <OfferBox
                            image={IOSLogo}
                            imageAlt={'deeplai career ios developer'}
                            name={routes.CareersIOSDeveloper.pageTitle}
                            link={getLink([routes.CareersIOSDeveloper])}
                        />
                    }
                    {careersEnabled.list && careersEnabled.javascript &&
                        <OfferBox
                            image={JsLogo}
                            imageAlt={'deeplai career javascript developer'}
                            name={routes.CareersJavascriptDevelopers.pageTitle}
                            link={getLink([routes.CareersJavascriptDevelopers])}
                        />
                    }
                    {careersEnabled.list && careersEnabled.python &&
                        <OfferBox
                            image={PythonLogo}
                            imageAlt={'deeplai career python developer'}
                            name={routes.CareersPythonDeveloper.pageTitle}
                            link={getLink([routes.CareersPythonDeveloper])}
                        />
                    }
                    {careersEnabled.list && careersEnabled.constructionTeamLeader &&
                        <OfferBox
                            image={ConstructionTeamLeaderLogo}
                            imageAlt={'deeplai career Construction Team Leader'}
                            name={routes.CareersConstructionTeamLeader.pageTitle}
                            link={getLink([routes.CareersConstructionTeamLeader])}
                        />
                    }
                    <div className={css.separator}/>
                    {careersEnabled.list && careersEnabled.digitalMarketingSpecialist &&
                        <OfferBox
                            image={DMSLogo}
                            imageAlt={'deeplai career Digital Marketing Specialist'}
                            name={routes.CareersDigitalMarketingSpecialist.pageTitle}
                            link={getLink([routes.CareersDigitalMarketingSpecialist])}
                        />

                    }
                    {careersEnabled.list && careersEnabled.salesAdmin &&
                        <OfferBox
                            image={SALogo}
                            imageAlt={'deeplai career Sales Admin'}
                            name={routes.CareersSalesAdmin.pageTitle}
                            link={getLink([routes.CareersSalesAdmin])}
                        />

                    }
                    {careersEnabled.list && careersEnabled.internationalBusinessDevelopmentManagerDirectOperations &&
                        <OfferBox
                            image={IBDDOLogo}
                            imageAlt={'deeplai career International Business Development Manager Direct Operations'}
                            name={routes.CareersManagerDirectOperations.pageTitle}
                            link={getLink([routes.CareersManagerDirectOperations])}
                        />
                    }
                    {careersEnabled.list && careersEnabled.internationalBusinessDevelopmentManagerIndirectOemOperations &&
                        <OfferBox
                            image={IBDIOOLogo}
                            imageAlt={'deeplai career International Business Development Manager Indirect OEM Operations'}
                            name={routes.CareersManagerIndirectOEMOperations.pageTitle}
                            link={getLink([routes.CareersManagerIndirectOEMOperations])}
                        />
                    }
                    {careersEnabled.list && careersEnabled.devOps &&
                        <OfferBox
                            image={DevOpsLogo}
                            imageAlt={'deeplai career devops'}
                            name={routes.CareersDevOps.pageTitle}
                            link={getLink([routes.CareersDevOps])}
                        />
                    }
                </div>

                <Footer/>

            </div>
        </Layout>
    );
};

export default CareersPage;
